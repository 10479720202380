export const LoadingPage = ({ progress }: { progress: number }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-100 to-gray-200">
      <div className="relative w-24 h-24">
        <div className="absolute top-0 left-0 w-full h-full  bg-gradient-to-br from-gray-100 to-gray-200 rounded-full"></div>
        <div
          className="absolute top-0 left-0 w-full h-full border-8 border-red-300 rounded-full animate-spin"
          style={{
            borderTopColor: "transparent",
            animation: "spin 1s linear infinite",
          }}
        ></div>
      </div>
      <p className="mt-4 text-xl font-semibold text-black">
        검사결과를 불러오고 있습니다.
      </p>
      <div className="w-64 h-2 mt-4 bg-red-300 rounded-full">
        <div
          className="h-full bg-white rounded-full transition-all duration-300 ease-out"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <p className="mt-2 text-sm text-blue-100">{Math.round(progress)}%</p>
    </div>
  );
};

export default LoadingPage;
