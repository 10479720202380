import "@/components/common/Sentry";
import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import QueryProvider from "./providers/QueryProvider";
import BrowserRouterProvider from "./providers/BrowserRouterProvider";
import SentryErrorProvider from "./providers/SentryProvider";
import { ErrorBoundary, type FallbackProps } from "react-error-boundary";
import ErrorFallback from "./components/common/error/ErrorFallback";
import LoadingPage from "./components/common/LoadingPage";

const fallbackRender = (props: FallbackProps) => {
  return <ErrorFallback {...props} />;
};

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Suspense fallback={<LoadingPage progress={0} />}>
      <SentryErrorProvider>
        <ErrorBoundary fallbackRender={fallbackRender}>
          <QueryProvider>
            <BrowserRouterProvider />
          </QueryProvider>
        </ErrorBoundary>
      </SentryErrorProvider>
    </Suspense>
  </StrictMode>
);
